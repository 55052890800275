
import '../CSS/style.scss'

import $ = require("jquery");

$('.navbar--contact-buttons-phone').on('click', function (e) {

    var target = $(e.target);

    e.preventDefault();
    e.stopImmediatePropagation();

    if (!$('.navbar--contact-buttons-phone-popup').hasClass('active')) {
        $('.navbar--contact-buttons-phone-popup').addClass('active');
    } else if (target.hasClass('phone-popup-close')) {
        $('.navbar--contact-buttons-phone-popup').removeClass('active');
    } else if (target.hasClass('phone-number')) {
        window.location.href = $(this).find('.phone-number').attr('href');
    }

});
//npm i --save-dev @types/jquery

(async function () {
    const dpMenu = document.querySelectorAll(
        ".subsite-menu--categories--container a"
    ) as any;
    let mouseIn = false;
    const subsiteMenu = document.querySelector(".subsite-menu") as any;
    if (dpMenu && dpMenu.length > 0) {
        const active = ["category_is_active", "show_only_second"];
        const subMenuContainer = document.querySelector(".subsite-menu--sites") as any;
        for (let dp of dpMenu) {
            dp.addEventListener("mouseenter", async () => {
                dpMenu.forEach((e) => e.classList.remove(...active));
                dp.classList.add(...active);
                subMenuContainer.classList.add(...active, "is-active");
                const menuTarget = dp.getAttribute("data-site-category");
                const subMenu = document.querySelector("." + menuTarget);
                //console.log(menuTarget,subMenu)
                if (menuTarget && subMenu) {
                    subMenuContainer
                        .querySelectorAll(".container > div")
                        .forEach((e) => e.classList.remove(...active));
                    subMenu.classList.add(...active);
                    const subMenuThirds =
                        subMenuContainer.querySelectorAll(".is-being-viewed");
                    for (let third of subMenuThirds) {
                        third.classList.remove("is-being-viewed");
                    }
                }
            });
        }
        subsiteMenu.addEventListener("mouseenter", async () => {
            mouseIn = true;
        });
        subsiteMenu.addEventListener("mouseleave", async () => {
            mouseIn = false;
            setTimeout(() => {
                if (!mouseIn) {
                    for (let dp of dpMenu) {
                        dp.classList.remove(...active);
                        subMenuContainer.classList.remove(...active, "is-active");
                        const menuTarget = dp.getAttribute("data-site-category");
                        const subMenu = document.querySelector("." + menuTarget);
                        //console.log(menuTarget,subMenu)
                        if (menuTarget && subMenu) {
                            subMenuContainer
                                .querySelectorAll(".container > div")
                                .forEach((e) => e.classList.remove(...active));
                            subMenu.classList.remove(...active);
                        }
                    }
                }
            }, 500);
        });
    }


    const borderTP = document.querySelector('.subsite-menu--sites') as any;
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-early_childhood_education"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#4abab8";
    })
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-special_education__disability_services"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#e23d3b";
    })
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-behavior_health"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#ffbb00";
    })
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-community__social"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#b7805c";
    })
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-student_success"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#f5865c";
    })
    document.querySelector('.subsite-menu--categories .subsite-menu--category[data-site-category="subsite-category-fitness__health"]').addEventListener('mouseenter', function () {
        borderTP.style.borderColor = "#37a564";
    })
})();

// Padding for subsite body
$('body').css('padding-top', $('.navbar-container-fixed').outerHeight());

var $parentNavLi = $('.header .menu-item-has-children');

$parentNavLi.each(function () {
    var $this = $(this),
        $link = $this.children('a'),
        $mobileToggle = $this.find('.mobileToggle'),
        $subMenu = $this.children('.sub-menu'),
        activeMobileClass = 'item-active-mobile',
        activeClass = 'item-active';

    $link.on('mouseenter', function () {
        $this.addClass(activeClass);
    });

    $this.on('mouseleave', function () {
        $this.removeClass(activeClass);
    });

    $mobileToggle.on('click', function (e) {
        e.preventDefault();
        $this.toggleClass(activeMobileClass);
        $subMenu.slideToggle();
    });
});

//$('[data-class="header"]').USER_MobileNav();

// Subsite menu functionality
$('.subsite-menu--category').click(function (e) {

    e.preventDefault();

    var category = $(this).data('siteCategory');

    $('.subsite-menu--sites').addClass('is-active');
    $('.subsite-menu--sites--container').removeClass('category_is_active');
    $('.' + category).addClass('category_is_active');
    $('.subsite-menu--category').removeClass('category_is_active');
    $(this).addClass('category_is_active');

    $('.subsite-menu--sites').css('border-color', $(this).data('categoryColor'));

    // Hide sublinks if user browsing
    if (category !== 'subsite-category-' + $('.subsite-menu--sublinks.is-being-viewed').data('category')) {
        $('.subsite-menu--sublinks.is-being-viewed').addClass('user-browsing');
    } else {
        $('.subsite-menu--sublinks.is-being-viewed').removeClass('user-browsing');
    }

});

// Hide sites menu if user clicks anywhere but menu

$('body').click(function (e) {
    if (!$(e.target).closest('.subsite-menu').length) {
        $('.subsite-menu--sites').removeClass('is-active');
        // $('.subsite-menu--category').removeClass('category_is_active');
    }
});

// Third level menu items hover action
// Show third level
$('.subsite-menu--site').mouseover(function () {
    var slug = $(this).data('subsiteSlug');
    // Hide other sublink menus if one menu is being viewed
    //if($('.subsite-menu--sublinks[data-subsite-slug="' + slug + '"]').length) {
    $('.subsite-menu--sublinks.is-being-viewed').addClass('user-browsing');
    //}
    // Add is active class
    $('.subsite-menu--sublinks[data-subsite-slug="' + slug + '"]').addClass('is-active');
});

// On mouse leave
$('.subsite-menu--site').mouseout(function () {
    var slug = $(this).data('subsiteSlug');

    // If active category is the same category being viewed now then show sublinks again
    if ($('.subsite-menu--category.category_is_active').data('siteCategory') == 'subsite-category-' + $('.subsite-menu--sublinks.is-being-viewed').data('category') && !$('.subsite-menu--sublinks.is-active').length) {
        $('.subsite-menu--sublinks.is-being-viewed').removeClass('user-browsing');
    }

    $('.subsite-menu--sublinks[data-subsite-slug="' + slug + '"]').removeClass('is-active');
});

// Third level items hover state
$('.subsite-menu--sublink').mouseover(function () {
    $(this).css('background-color', $(this).data('categoryColor'));
    $(this).css('color', '#fff');
    // $('.subsite-menu--sublinks').addClass('user-browsing')
    // $(this).closest('.subsite-menu--sublinks').removeClass('user-browsing')
});

$('.subsite-menu--sublink').mouseout(function () {
    if (!$(this).hasClass('is-active')) {
        $(this).css('background-color', '');
        $(this).css('color', '');
    }
});